.right-drawer {
  div {
    border-radius: 6px 0px 0px 6px;
    .right-sidebar {
      width: 330px;
      height: 100%;
      padding: 16px;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 6px;
      .right-sidebar-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        .right-sidebar-title {
          font-size: 16px;
          font-weight: 700;
          margin: 0px 0px 16px 0px;
        }
        .right-sidebar-button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          padding: 6px 0px;
          font-size: 16px;
          font-weight: 500;
          border-width: 0;
          border-radius: 6px;
          margin-bottom: 6px;
          background-color: #ffffff;
          color: #006782;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
          cursor: pointer;
          .button-arrow {
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }
}
