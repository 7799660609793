body {
  margin: 0;
}

html,
body,
#root,
.app {
  height: 100%;
}

.page-wrapper {
  height: calc(100% - 64px);
  position: relative;
  top: 64px;
}

.map {
  height: 100%;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTableCell-body {
  font-size: 13px !important;
}

.MuiChip-labelSmall {
  padding: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiPagination-ul {
  justify-content: center !important;
  margin-top: 15px !important;
}

.warrning-card{
  background-color: crimson;
  color: white;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  
}