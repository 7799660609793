.saved-plans-wrapper{
    display: flex;
    padding: 13px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;

    .saved-plans-info{

        .plan-timeslot{
            font-size: 16px;
            line-height: 15px;
        }
        .plan-info{
            color: #4A5153;
            font-size: 12px;
            line-height: 12px;
        }
    }

    .plan-actions{
        display: flex;
        .system-style{
            background-color: #E2FEEA;
            color: #689900;
            padding: 7px 12px;
            border-radius: 4px;
            font-size: 12px;
            line-height: 12px;
            margin-right: 25px;
            align-self: center;
        }

        .cfr-style{
            background-color: #C7F1FF;
            color: #006782;
            padding: 7px 20px;
            border-radius: 4px;
            font-size: 12px;
            line-height: 12px;
            margin-right: 25px;
            align-self: center;
        }

        .plan-icons{
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            
            .delete-icon{
                position: absolute;
                top: 10px;
                right: 15px;
                font-size: 20px;
            }

            .arrow-icon{
                position: absolute;
                bottom: 10px;
                right: 15px;
                font-size: 17px;
                
            }
        }
    }
  }


  .validate-alert-dialog{
	.MuiDialog-paperWidthSm{
		height: auto !important;
		min-width: 25% !important;
        padding: 7px;
	}

	.MuiDialogTitle-root{
		padding: 12px 24px !important;
	}

	.MuiTypography-h6{
		text-align: center;
		padding-bottom: 0;
		font-size: 15px !important;
	}
	
	.alert-desc{
		margin-left: 15px !important;
    	color: #4A5153;
		margin: 0;
		margin-bottom: 14px;
		font-size: 15px !important;
        font-family: sans-serif;
	}

	.MuiDialogActions-root{
		justify-content: space-around !important;
        padding: 0 !important;
	} 

	.MuiButton-text{
		min-width: 70px !important;
		color: #3592ff !important
	}

}