.create-plan-modal {
  .MuiDialog-paperWidthSm{
    min-width: 75% !important;
  }
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  .content-wrapper {
    display: flex;
    justify-content: center;
    padding: 0px 16px !important;
    font-size: 16px;
    .left-side-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      .timeslot-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        img {
          width: 18px;
          height: 18px;
        }
        h3 {
          margin: 0px 8px;
        }
      }
    }
    .right-side-wrapper {
      width: 100%;
      padding-left: 16px;
      height: 100%;
      overflow-y: auto;
      .driver-title {
        display: flex;
        align-items: center;
        margin: 16px 0px;
        img {
          width: 18px;
          height: 18px;
        }
        h3 {
          margin: 0px 8px;
        }
      }
      .input {
        width: 50%;
        height: 32px;
        padding: 0px 8px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }
  }
  .MuiDivider-vertical {
    width: 10px !important;
  }
  .modal-action {
    justify-content: space-between;
    padding: 10px 16px;
    .btn {
      height: 34px;
      padding: 8px 20px;
      border: 0;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      &:disabled {
        background-color: #d8d8d8;
        cursor: not-allowed;
      }
      img {
        margin-right: 4px;
      }
    }
    .primary {
      background-color: #006782;
    }
    .half-width {
      width: 50%;
    }
    .self-end {
      margin-left: auto;
    }
    .secondary {
      margin-right: 8px;
      background-color: #50a3e6;
    }
    .disabled {
      background-color: #d8d8d8;
      cursor: not-allowed;
    }
  }
}

.list-type-none {
  list-style-type: none;
}

.mb-6 {
  margin-bottom: 24px;
}

.pointer {
  cursor: pointer;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.full-height {
  height: 100%;
}

.display-none {
  display: none;
}
