.gallery-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}

.categpry-title{
    color: #806C00;
    font-size: 17px;
    font-family: 'Aref Ruqaa', sans-serif;
    text-align: center;
    margin: 5px;
}

    .copy-rights{
        position: relative;
        background-color: #7393B3;
        border-top: 5px solid rgba(0,0,0,.2);
        text-align: center;
        padding: 8px;
        font-size: 12px;
        margin-bottom: 30px;
        margin-top: 0;
    }
  