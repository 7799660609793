.MuiDialog-paperWidthSm {
  // max-width: 50% !important;
  min-width: 30% !important;
  // height: 40% !important;
}

@media (max-width: 1075px) {
  .MuiDialog-paperWidthSm {
    min-width: 100% !important;
  }
}


.undelivered-messages-modal{
  .MuiDialog-paperWidthSm {
    min-width: 60% !important;
  }
}