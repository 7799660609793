.apologize-container{
    .return-btn{
        margin-top: 15px;
        background-color: #006782;
        color: white;
    }
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-image: url("https://imagecdn.prod.floward.com/web/files/tickets/99e2319d-0a1d-4543-ab47-955341d7e67c.jpg");
    background-size: cover;
    background-repeat: no-repeat; 

    .apologize-wrapper{
        text-align: center;
    
        .welcome-msg{
            color: #006782;
            font-size: 30px;
            font-family: 'Aref Ruqaa', sans-serif;
        }

        .invitee-name{
            font-family: 'Aref Ruqaa', sans-serif;
            font-size: 30px;
            margin: 0;
            color: #219CAC; 
        }
    
        .invitees-count{
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 13px 20%;
            height: 30px;
            background-color: #51BAC7;
            border: 1px solid #019ed9;
            border-radius: 5px ;
            color: white;
            font-size: 17px;
            padding: 3px;
            font-weight: bold;

        }

        .apologize-header{
            background-color: #A91B0D;
            font-size: 15px;
            padding: 8px;
            border-radius: 8px;
            color: white;
            margin: 0 25%;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .confirm-header{
            color: #006782;
            font-size: 20px;
            padding: 50px 0;
        }
    }

    .location-btn-wrapper{
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 30px;
        .location-btn{
            background-color: #51BAC7;
            color: white;
            padding: 7px;
            text-decoration: none;
            border-radius: 5px;
            font-size: 14px;
        }
    }

    .copy-rights{
        position: relative;
        background-color: #eee;
        border-top: 5px solid rgba(0,0,0,.2);
        text-align: center;
        padding: 8px;
        font-size: 12px;
        margin-bottom: 30px;
        margin-top: 0;
    }
}

.welcome-msg{
    color: #006782;
    font-size: 20px;
    font-family: 'Aref Ruqaa', sans-serif;
    text-align: center;
}