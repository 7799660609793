.MuiFormGroup-root {
  z-index: 2001 !important;
}
.MuiFormControlLabel-root {
  align-items: flex-end !important;
}

.reload-icon {
  font-size: 29px !important;
  cursor: pointer;
}

.user-name {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 19px;
}

.header-ops-img {
  width: 70px;
  border-radius: 5px;
  cursor: pointer;
}

.ops-wrapper {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 100%;
  .ops-img {
    width: 70%;
    cursor: pointer;
    border-radius: 5px;
  }
  .ops-name {
    // white-space: nowrap;
    font-family: system-ui;
  }
}

.user-name-style {
  font-size: 14px;
  font-family: sans-serif;
  white-space: nowrap;
}

.map-zoom-wrapper {
  display: flex;
  position: absolute;
  left: 45%;
  top: 20px;
  .MuiButtonBase-root {
    margin: 0 3px;
    padding: 0;
    min-width: 60px !important;
    height: 28px;
  }

  .MuiSvgIcon-root {
    font-size: 28px;
    cursor: pointer;
  }
}

// .MuiPaper-elevation8 {
// 	top: 50px !important;
// 	// left: 235px !important;
// }

.MuiButton-startIcon {
  margin-right: 2px !important;
}