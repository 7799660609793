.assign-orders-wrapper {
  display: flex;

  .MuiListItem-container {
    width: 95% !important;
  }

  .free-drivers-list-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: sans-serif;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;

    .list-title-wrapper {
      display: flex;
      align-items: flex-end;
      .MuiSvgIcon-root {
        font-size: 1.2rem;
      }
      p {
        margin: 0px;
        font-size: 14px;
      }
    }
    .all-free-drivers-checkbox {
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #0175ff;
      }
    }
  }

  .MuiListItemSecondaryAction-root {
    // right: 20px !important;
    padding: 10px 0px !important;
  }

  .MuiListItem-gutters {
    padding-left: 0px !important;
  }

  .ready-orders-wrapper {
    margin-bottom: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    display: inline-block;
  }

  .MuiList-root {
    height: 650px;
    overflow-y: scroll;
    padding-left: 10px;
  }
}

.optimized-list-wrapper {
  .MuiListItemIcon-root {
    min-width: 30px !important;
  }

  .optimized-list-header {
    background-color: #efefef;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
  }

  .order-item-text {
    cursor: pointer;
  }
}

.MuiDialogContent-dividers {
  padding: 16px 10px !important;
}

.assign-alert-dialog {
  .MuiDialog-paperWidthSm {
    height: 15% !important;
    min-width: 25% !important;
  }

  .MuiDialogTitle-root {
    padding: 12px 24px !important;
  }

  .MuiTypography-h6 {
    text-align: center;
    font-weight: bold;
    padding-bottom: 0;
    font-size: 15px !important;
  }

  .alert-desc {
    text-align: center;
    color: black;
    margin: 0;
    margin-bottom: 13px;
  }

  .MuiDialogActions-root {
    justify-content: space-around !important;
  }

  .MuiButton-text {
    min-width: 100px !important;
    color: #3592ff !important;
  }
}

.driver-assign-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .driver-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    .driver-info {
      display: flex;
      flex-direction: column;
      width: 60%;
      .primary-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Roboto';
      }
      .secondary-info-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        font-family: 'Roboto';
        p {
          margin-left: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .secondary-info-wrapper {
    flex-direction: column;
  }
  .driver-info-wrapper {
    width: 65% !important;
  }
  .info {
    height: 40px !important;
  }
  .assign-driver-header-wrapper {
    width: 65% !important;
  }
}

.company-field {
  width: 100px;
  text-align: center;
}
.assign-driver-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  font-weight: bold;
  text-align: center;
  p {
    padding: 0px 8px;
  }
}

.bold-font {
  font-weight: bold;
}
