//material ui
// .MuiBackdrop-root {
//   background-color: transparent !important;
// }

.MuiDrawer-paperAnchorLeft {
  top: 65px !important;
  left: 0px !important;
}

.MuiDrawer-paperAnchorRight {
  top: 65px !important;
  right: 0px !important;
}

//trip ide
.left-side-wrapper {
  width: 400px;
  .MuiButtonBase-root {
    min-width: 135px !important;
    // min-height: 100% !important;
  }
  .MuiTab-textColorSecondary.Mui-selected {
    color: #006782 !important;
  }

  .MuiTab-wrapper {
    font-size: 12px !important;
  }
  .MuiTab-root {
    line-height: 1.3 !important;
  }
  .MuiTab-labelIcon {
    min-height: 62px !important;
  }

  .MuiChip-root {
    padding: 0px 20px;
    margin-top: 10px;
  }

  .drivers-list-title {
    min-height: 35px !important;
    padding: 7px 0;
    background-color: #eee;
    p {
      text-align: center;
      font-weight: bold;
    }
  }

  .driver-name {
    font-size: 14px;
    text-align: center;
  }

  .trip-list-wrapper {
    max-width: 100% !important;
    height: 98% !important;
    .MuiDivider-root {
      margin-left: 0;
      margin-right: 0;
    }
    .list-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 2px;

      .MuiAvatar-root {
        margin-bottom: 3px;
      }

      .MuiListItemAvatar-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
      }

      .MuiListItemText-root {
        padding-left: 15px;
      }
      .list-content-style {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .orders-counter {
          border: 1px lightgrey solid;
          border-radius: 50%;
          width: 55px;
          height: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            color: #006782;
            font-size: 13px;
          }
        }

        .list-item-icon {
          color: #999;
        }
        p {
          color: #999;
          margin: 0;
        }
      }
    }
  }
}

//driver trip
.driver-trip-header {
  min-height: 40px !important;
  background-color: #eee;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding-top: 3px;
  .driver-trip-title {
    display: flex;
    align-items: center;
    color: black;
    p {
      text-align: center;
      font-weight: bold;
    }
    .back-icon {
      font-size: 30px;
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      color: #4695f6;
    }
  }
}

.driver-trip-wrapper {
  .MuiListItem-root {
    display: flex;
    justify-content: center;
  }
  .trip-details-content {
    max-width: 100% !important;
    .MuiDivider-root {
      margin-left: 0;
      margin-right: 0;
    }
    .list-item {
      cursor: pointer;
      .list-content-style {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .orders-counter {
          border: 1px lightgrey solid;
          border-radius: 50%;
          width: 55px;
          height: 55px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            color: #4695f6;
            font-size: 13px;
          }
        }

        .list-item-icon {
          color: #999;
        }
        p {
          color: #999;
          margin: 0;
        }
      }
    }
  }
}
.all-order-pagination {
  margin-bottom: 80px;
  .MuiButtonBase-root {
    min-width: 32px !important;
  }
}
