.custom-border{
    border: 2px solid #006782;
    padding: 0.270rem 0.70rem;
    border-radius: 0.25rem;
    .css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
      border: 0;
    }

    input{
      font-size: 16px !important;
    }
  }


  .MuiAutocomplete-listbox{
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    direction: rtl;
  }

  .MuiInputBase-root{
    padding-right: 10px !important;
  }

  .map-auto-complete{
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 0.25rem;
  }