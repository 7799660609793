.plans-list-wrapper {
	.MuiListItemIcon-root {
		min-width: 30px !important;
	}

	.plans-list-header {
		// background-color: #EFEFEF;
		display: flex;
		justify-content: space-between;
		border-radius: 5px;
        padding: 0 16px !important;
	}

    .MuiButtonBase-root{
        min-width: 80px !important;
        margin-left: 5px;
        font-weight: normal !important;
        font-size: 13px !important;
    }

    .driver-title{
        span{
            color: black;
            font-size: 14px !important;
        }
    }

    .assign-btn{
        text-transform: none;
        background-color: #50A3E6;
        color: white;
        font-size: 13px !important;
        &:hover{
        background-color: #50A3E6;
        }
    }

    .disptach-btn{
        text-transform: none;
        background-color: #22BB33;
        color: white;
        font-size: 13px !important;
        &:hover{
            background-color: #22BB33;
            }
    }

	.order-item-text {
		cursor: pointer;
        .delete-icon{
            background-color: #FE3D5E;
            border-radius: 3px;
            font-size: 22px;

        }

        .menu-icon{
            background-color: #EFEFEF;
            padding: 2px;
            border-radius: 3px;
            margin-left: 5px;
            font-size: 10px;
        }
	}
}

.no-plan-details-wrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        color: #006782;
        font-size: 18px;
    }
}