.timeslots-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 16px;
  .timeslot-label {
    display: block;
    width: 48%;
    height: 42px;
    margin-bottom: 10px;
    cursor: pointer;
    .timeslot-radio {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
    .timeslot-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      height: 42px;
      border-radius: 6px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
    .timeslot-radio:checked + .timeslot-content {
      color: #006782;
      font-weight: 500;
      border: 1px solid #006782;
    }
    .timeslot-radio:disabled + .timeslot-content {
      color: #ffffff;
      background-color: #d8d8d8;
      cursor: not-allowed;
    }
  }
}
