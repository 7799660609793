.bulk-upload-drivers {
  display: flex;
  flex-direction: column;
  padding: 16px 24px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  .file-input-wrapper {
    display: flex;
    flex-direction: column;
    .file-input-label {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 14px;
      .template-download-link {
        display: inline-block;
        color: #006782;
        text-decoration: none;
        cursor: pointer;
        font-weight: normal;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .file-input {
      width: 40%;
      padding: 8px;
      border: 1px solid #ced4da !important;
      border-radius: 6px;
    }
  }
  .alert-bar {
    display: flex;
    align-items: center;
    margin: 24px 0px;
  }
  .progress-indicator {
    text-align: center;
    font-weight: bold;
  }
}

.bulk-orders-list {
  th:first-child {
    width: 15.5% !important;
  }
  th:nth-child(2) {
    width: 10% !important;
  }
  th:nth-child(3) {
    width: 11% !important;
  }
  th:nth-child(4) {
    width: 10% !important;
  }
  th:nth-child(5) {
    width: 20% !important;
  }
  th:nth-child(6) {
    width: 11% !important;
  }
  th:nth-child(7) {
    width: 12.5% !important;
  }
  th:nth-child(8) {
    width: 10% !important;
  }
  .confirmation-table-scroll {
    width: calc(100% + 15px);
    height: 300px;
    overflow-y: scroll;
    margin-bottom: 30px;
    margin-right: -30px;
    table {
      border-collapse: collapse;
    }
    tr {
      border-bottom: 1px solid #fff;
    }
    td {
      padding: 10px 5px;
      align-items: center;
      justify-content: center;
      word-wrap: anywhere;
      font-size: 14px;
    }
    td:first-child {
      width: 15.5% !important;
    }
    td:nth-child(2) {
      width: 10% !important;
    }
    td:nth-child(3) {
      width: 11% !important;
    }
    td:nth-child(4) {
      width: 10% !important;
    }
    td:nth-child(5) {
      width: 20% !important;
    }
    td:nth-child(6) {
      width: 11% !important;
    }
    td:nth-child(7) {
      width: 12.5% !important;
    }
    td:nth-child(8) {
      width: 10% !important;
    }
  }
}
.align-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
}

.primary-color {
  color: #006782 !important;
}

.full-width {
  width: 100%;
}

.error-msg {
  svg {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
  color: red;
  font-size: 12px;
  line-height: 20px;
}

.m-b-0 {
  margin-bottom: 0;
}

.MuiDialog-paperWidthLg {
  min-width: 70% !important;
  height: 100% !important;
}
@media (max-width: 1075px) {
  .MuiDialog-paperWidthSm {
    min-width: 100% !important;
  }
}
