//material table styles

.material-table-wrapper{
  border: 1px solid #eee;
    .MuiTableHead-root{
      border: 1px solid #eff0f1;
    }
    
    .MuiTableCell-root{
      padding: 10px;
    }
    
    // .MuiTableRow-root:hover {
    //   background-color: #e8ebf2 !important;
    // }
    
    button:has(.excel-export-btn){
    position: absolute ;
    left: 0px ;
    cursor: pointer;
      &:focus{
          outline: none;
      }
    }
    
    .MuiToolbar-root {
        direction: rtl;
        display: flex;
        justify-content: space-between;
      span:nth-child(3) {
        position: absolute ;
        left: 0px ;cursor: pointer;
        &:focus{
            outline: none;
        }
      }
    }
  
    button:has(.corporate-orders-table-title) {
      position: absolute ;
      left: 15px ;
      color: rgb(49, 49, 49);
      &:hover {
        background-color: transparent;
      }
      p {
        font-size: 18px;
        font-weight: bold;
        color: rgb(49, 49, 49);
        i {
          margin-left: 10px;
        }
      }
    }
  
    .MuiButtonBase-root{
      outline: none;
    }
  
    .MuiTableCell-head{
      text-align: center;
    }
  
    .MuiTableRow-root p {
      margin: 0;
    }
  
    .MuiTableRow-root td{
      text-align: center;
    }
  
    .MuiTableRow-root td:has(.MuiButtonBase-root){
      text-align: left;
    }
  
    .MuiTableRow-root:nth-child(2n) {
      background-color: #f9f9f9;
    }
  }
  
  .MuiPopover-paper{
    max-height: 40% !important;
  }
  
  .MuiCheckbox-root{
    color: #006782 !important
  }

  .MuiToolbar-root > div:first-child {
    display: none !important;
  }

  .MuiTabs-root{
    overflow: auto !important;
    .MuiTabs-scroller{
      overflow: auto !important;
      .MuiTabs-flexContainer{
        overflow: auto !important;
      }
    }
  }

  .MuiSvgIcon-fontSizeSmall{
    display: none !important;
  }
  .material-table-wrapper .MuiToolbar-root{
    justify-content: right !important;
    // overflow-x: scroll !important;
  }

  .invitees-tab-wrapper{
    .MuiTab-root{
      min-width: 80px !important;
    }
  }

  .attendence-wrapper{
    .material-table-wrapper .MuiToolbar-root{
      justify-content: space-between !important;
    }
  }


