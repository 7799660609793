.lmfs-container {
  height: 100%;
  display: flex;
}
.lmfs-map {
  height: 100%;
}
.lmfs-mapContainer {
  width: 100%;
}

.lmfs-tasks-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .back-icon{
    font-size: 30px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    color: #4695f6;
  };
  .driver-id-wrapper{
    .search-task-wrapper{
      display: flex;
      align-items: center;
    }
    margin-left: 8px;
    margin-top: 14px;
    .driver-title{
      font-family: Roboto;
      margin-bottom: 0;
      font-size: 11px;
    }
    .driver-id{
      font-size: 28px;
      font-family: Roboto;
    }
    .driver-tasks-title{
      font-size: 17px;
      font-family: Roboto;
      margin-right: 30px;
    }
  }
}

.trip-tasks-table{
  .MuiTableCell-root{
    padding: 14px !important;
  }
}