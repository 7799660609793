.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    .loader {
        border: 6px solid #f3f3f3;
        border-top: 6px solid #006782;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
      }    
  }
  

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  


  .modal-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    padding: 0 40px;
  }
  
  // .loading-icon {
  //   width: 100px;
  //   height: 100px;
  //   border-radius: 100%;
  //   position: relative;
  //   margin: 0 auto;
  
  //   &:before, &:after{
  //     content: "";
  //     position: absolute;
  //     top: -10px;
  //     left: -10px;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 100%;
  //     border: 10px solid transparent;
  //     border-top-color: $primary;
  //   }
  
  //   &:before{
  //     z-index: 100;
  //     animation: spin 1s infinite;
  //   }
  
  //   &:after{
  //     border: 10px solid #ccc;
  //   }
  // }
  
  // @keyframes spin {
  //   0% {
  //     -webkit-transform: rotate(0deg);
  //     -ms-transform: rotate(0deg);
  //     -o-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  
  //   100% {
  //     -webkit-transform: rotate(360deg);
  //     -ms-transform: rotate(360deg);
  //     -o-transform: rotate(360deg);
  //     transform: rotate(360deg);
  //   }
  // }
  
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #006782;
    color: #006782;
    box-shadow: 9999px 0 0 -5px #006782;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  
  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #006782;
    color: #006782;
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #006782;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #006782;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #006782;
    }
    30% {
      box-shadow: 9984px 0 0 2px #006782;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #006782;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #006782;
    }
    30% {
      box-shadow: 9999px 0 0 2px #006782;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #006782;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #006782;
    }
    30% {
      box-shadow: 10014px 0 0 2px #006782;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px#006782;
    }
  }
  