.orders-modal-wrapper {
	.MuiTab-wrapper {
		flex-direction: row;
		color: black;
	}

	.sorting-btn-wrapper {
		display: flex;
		justify-content: space-around;
		margin-bottom: 25px;
	}
}

.list-item {
	.dispatched-order-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.order-details {
			width: 65%;
			display: flex;
			justify-content: flex-start;
			.MuiSvgIcon-root {
				font-size: 15px !important;
				margin-right: 10px;
			}
		}
		p {
			margin: 0;
			color: black;
			font-size: 13px;
		}
	}
}

.camera-wrapper {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	.camera-close-btn {
		width: 70%;
		margin-top: 20px;
		margin-bottom: 10px;
	}
}

.add-order-bar {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.cancel-icon {
		position: absolute;
		right: 3px;
		top: 7px;
		font-size: 18px;
	}

	.MuiInputBase-root {
		width: 100%;
	}
}

.free-drivers-list-wrapper {
	.MuiInputBase-root {
		width: 100% !important;

		.MuiInputBase-input {
			width: 100% !important;
		}
	}
}

.group-details {
	.MuiListItemText-secondary {
		color: red !important;
	}
}

.scanned-order-id{
	font-size: 17px !important;
    font-weight: bold;
}