.info-window-wrapper {
	div {
		div {
			z-index: 1000 !important;
		}
	}
}

.gm-style-iw-t {
	margin-bottom: 38px;
	// bottom: 18px !important;
	.gm-style-iw {
		max-width: 230px !important;
	}
}

.gm-style-iw-d {
	overflow: auto !important;
}

.gm-style .gm-style-iw-c {
	padding: 9px !important;
}

.gm-style .gm-style-iw-t::after {
	top: -3px !important;
}

.window-info-wrapper div {
	z-index: -10000 !important;
}

.marker-info-window {
	padding: 0 7px;
	direction: rtl;
	.MuiSvgIcon-root {
		font-size: 20px !important;
	}
	h3 {
		display: flex;
		align-items: right;
		color: #333;
		margin: 8px 0;
		font-size: 14px !important;

		.MuiSvgIcon-root {
			margin-right: 10px;
			color: #4695f6;
		}
	}
	h3:first-child {
		margin-top: 3px;
		margin: 10px 0;
		font-size: 13px !important;
	}
	h3 {
		text-align: right;
		margin: 10px 0;
		font-size: 13px !important;
	}

	.location{
		text-decoration: underline;
		cursor: pointer;
	}
}

//hide windowInfo close icon
.gm-ui-hover-effect {
	display: none !important;
}

.window-info-close-icon {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	font-size: 19px !important;
}
