
.greetings-container{
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .greetings-page-wrapper {
    text-align: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    .greetings {
        color: #006782; 
        font-size: 70px; 
        animation: bounce 2s infinite;
        font-weight: bold;
        font-family: 'Aref Ruqaa', sans-serif;
        
      }
      .family-name{
        color: #006782; 
        font-size: 90px; 
        animation: bounce 2s infinite;
        font-weight: bold;
        font-family: 'Aref Ruqaa', sans-serif;
      }
      
    .invitee-name {
        color: #A38A00; 
        animation: pulse 2s infinite; 
        font-size: 80px; 
        font-weight: bold;
        font-family: 'Aref Ruqaa', sans-serif;
        padding: 80px 0px 80px 0px;
        // font-family: 'Reem Kufi Variable', sans-serif;
      }
  }
}
  
  @keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }

  .invitee-background-img{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .stand-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }


  .greetings-container{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .greetings-page-wrapper {
      text-align: center; 
      display: flex;
      flex-direction: column;
      justify-content: center;

        .stand-family-name{
          color: #006782; 
          font-size: 120px; 
          font-weight: bold;
          font-family: 'Aref Ruqaa', sans-serif;
          z-index: 1000;
        }
        
      .extra-info-name {
          color: #A38A00; 
          font-size: 100px; 
          font-weight: bold;
          font-family: 'Aref Ruqaa', sans-serif;
          padding: 80px 0px 80px 0px;
          z-index: 1000;
          // font-family: 'Reem Kufi Variable', sans-serif;
        }
    }
  }