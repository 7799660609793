.order-info-titles {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0;
    color: #1876d0;
  }
}

.MuiTableRow-head {
  .MuiTableCell-head {
    color: #006782;
  }
}

.show-hub-btn {
  display: flex !important;
  justify-content: center !important;
  margin-top: 15px;
}

.ops-modal-title {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgrey;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    background: #fff;
    padding: 0 10px;
    font-family: Roboto;
  }
}
