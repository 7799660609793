.login-page-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.input {
		font-size: 16px;
		height: 24px;
		padding: 6px 12px;
		border: 1px solid #ced4da !important;
		border-radius: 4px;
		margin-bottom: 5px;
	}
  
	.login-form-block{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		font-family: Arial, Helvetica, sans-serif;
		h2{
			margin: 0;
			font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		}
		p{
			font-size: 12px;
			color: "#4A5153";	
			font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		}
		.login-btn{
			margin-top: 10px;
			font-weight: normal;
			text-transform: none;
			background-color: #006782;
			color: white;
			padding: 8px 40px;
			&:hover{
				background-color: #006782;
			}
			font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		}
	}

	.cover-img-wrapper{
		height: 100%;
		display: flex;
		justify-content: center;
		background-image: url("https://floward.imgix.net/web/files/tickets/0bde8fb7-2e24-40c6-94f0-8152ee498cf9.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

@media only screen and (max-width: 960px) {
	.login-page-wrapper{
	  flex-direction: column-reverse;
	  align-items: normal;
	}
	.cover-img-wrapper{
		height: 50%;
	}
	.login-page-wrapper.MuiGrid-container{
		flex-wrap: nowrap;
	}	
  }
  