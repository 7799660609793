.add-driver-modal {
  .MuiDialogContent-dividers {
    padding: 0px !important;
  }
  .form {
    // display: grid;
    // grid-template-columns: 25% 25% 25% 25%;
    padding: 24px 0px;
    overflow-y: hidden;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    .input-wrapper {
      display: flex;
      margin-bottom: 16px;
      padding: 0px 24px;
      flex-direction: column;
      .input-label {
        margin: 7px 0;
        font-size: 14px;
        font-weight: bold;
      }
      .input {
        font-size: 16px;
        height: 24px;
        padding: 6px 12px;
        border: 2px solid #006782 !important;
        border-radius: 4px;
      }
      .date-picker {
        .MuiOutlinedInput-root {
          height: 38px;
        }
      }
    }
  }
}
.red-color {
  color: red;
}

.card-wrapper{
  margin-bottom: 10px;
  border: 1px solid #eee;
}


.polling-modal{
  .MuiDialog-paperWidthSm{
    width: 500px;
  }
}