.date-picker-wrapper-label {
  position: relative;
  margin-top: 16px;
  margin-bottom: 32px;
  .date-picker-label {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    left: 34px;
    font-weight: 700;
    color: #006782;
  }
  .date-picker {
    width: 260px;
    height: 38px;
    .react-date-picker__wrapper {
      flex-direction: row-reverse;
      border-radius: 6px;
      border: 0px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      .react-date-picker__inputGroup {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-shrink: 0;
        padding-right: 10px;
        font-weight: 700;
        .react-date-picker__inputGroup__day:focus-visible,
        .react-date-picker__inputGroup__year:focus-visible {
          outline: 0px;
        }
        .react-date-picker__inputGroup__month {
          appearance: none;
          width: 50px;
          position: relative;
          left: 20px;
          &:focus-visible {
            outline: 0px;
          }
        }
      }
      .react-date-picker__button {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 10px;
      }
    }
  }
}


#driver-sdate{
  text-align: right !important;
}